.footer {
  background-color: var(--color-black);
  opacity: 80%;
}

.footer p {
  font-family: var(--font-alt);
  color: var(--color-orange);
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  padding-bottom: 2rem;
}

.social-links {
  flex: 1;
  list-style: none;
  padding-top: 1rem;
}

.social-links li {
  margin: 1rem;
}

.social-links li:hover {
  border-bottom: 1px solid var(--color-orange);
}
