.menu-items {
  margin-top: 2rem;
  margin: 1rem;
}

.menu-items h2 {
  font-family: var(--font-alt);
  font-size: 2.5rem;
  text-align: center;
}

.menu-items h3 {
  background-color: white;
  font-family: var(--font-p-reg);
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  padding-top: 0.5rem;
}

.menu-items p {
  background-color: white;
  font-family: var(--font-p-bold);
  font-size: 0.9rem;
  text-align: center;
  padding: 0 2rem;
}

h1 {
  text-align: center;
}

.menu-padding {
  margin: 1rem 0 2rem 0;
}

.entree-padding {
  margin: 1rem 0 0 0;
}

.collapsible {
  background-color: var(--color-green);
  font-size: 2.5rem;
  text-align: center;
  margin: 0.5rem 0;
  border: solid 1px;
  border-radius: 1rem;
}

.collapsible svg {
  font-size: 1.5rem;
}

.collapsible:hover {
  background-color: white;
  transition: 0.3s ease;
  cursor: pointer;
}

.Collapsible__trigger.is-open svg {
  background-color: white;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  padding-bottom: 0.5rem;
  transform: rotate(-180deg);
}

