.location-bg {
  background: url("../../assets/cactus3.png");
  background-position: center 10rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.location-wrapper {
  background-color: var(--color-black);
  opacity: 80%;
  padding-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--color-orange);
  font-size: 2.2rem;
  text-align: center;
  font-family: var(--font-alt);
  margin-top: 2rem;
}

.info {
  color: white;
  font-family: var(--font-p-reg);
  padding: 0.1rem;
  font-size: 1.2rem;
}

.spacer {
  height: 24vh;
  background: transparent;
}

.hours-wrapper {
  background-color: var(--color-black);
  opacity: 80%;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  justify-content: center;
  align-items: center;
}

.contact-wrapper {
  background-color: var(--color-black);
  opacity: 80%;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  justify-content: center;
  align-items: center;
}

.contact-container {
  display: flex;
}

.contact-container svg {
  color: white;
  font-size: 1.4rem;
  margin-top: 0.3rem;
  margin-right: 0.5rem;
}

@media screen and (min-width: 2000px) {
  .location-bg {
    background-position: center 18rem;
  }

  .location-wrapper {
    padding-top: 20rem;
  }
}

@media screen and (min-width: 1500px) {
  .location-wrapper {
    padding-top: 15rem;
  }
}

@media screen and (max-width: 844px) {
  .title {
    margin-top: 0;
  }

  .location-wrapper {
    padding-top: 8rem;
  }

  .location-bg {
    background-attachment: scroll;
  }
}

