.about-wrapper {
  background: url("../../assets/cactus4.png");
  background-position: center 10rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 150px;
  display: flex;
  flex-direction: column;
}

.about-cactus-bg {
  margin-top: 80vh;
}

.about-bg {
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
}

.info-container {
  background-color: black;
  opacity: 80%;
  border-radius: 1rem;
}

.about-title {
  font-family: var(--font-base);
  color: var(--color-blue);
  font-weight: 200;
  letter-spacing: 0.04em;
  line-height: 4rem;
  font-size: 4rem;
  align-items: left;
  padding-top: 2rem;
}

.about-info {
  font-family: var(--font-p-bold);
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 4rem 2rem;
}

.about-down-btn {
  width: 100%;
  color: var(--color-blue);
  background: transparent;
  border: none;
  font-size: 4rem;
}

.about-down-btn:hover {
  color: var(--color-pink);
}

@media screen and (max-width: 844px) {
  .about-wrapper {
    background-attachment: scroll;
  }
  
  .about-bg {
    background-size: cover;
  }

  .about-title {
    font-size: 3rem;
  }

  .about-info {
    font-size: 1rem;
  }

  .info-container {
    height: auto;
    margin: 10rem 2rem;
  }
}

@media screen and (min-width: 844px) {
  .info-container {
    height: auto;
    margin: 20rem 20rem;
  }
}
