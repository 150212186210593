.menu-bg {
  background: url("../../assets/cactus1.png");
  background-position: center 10rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 150px;
  margin: 0;
}

.menu-cactus-bg {
  margin-top: 50vh;
}

.menu-wrapper {
  background-color: white;
  margin: 10rem 0 0 0;
}

.menu-title {
  background-color: white;
  width: 100%;
  font-family: var(--font-base);
  color: var(--color-green);
  text-align: center;
  font-weight: 200;
  letter-spacing: 0.04em;
  margin-bottom: 0rem;
  font-size: 4rem;
  padding-top: 7rem;
}

.menu-title span {
  font-size: 3rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.btn-download {
  margin: 0 20% 0 20%;
  padding: 0.25rem;
  background: var(--color-blue);
  font-family: var(--font-alt);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-white);
  border-radius: 1rem;
}

.btn-down {
  color: var(--color-green);
  background: transparent;
  border: none;
  font-size: 4rem;
}

.menu-down-btn:hover {
  color: var(--color-orange);
  transition: 0.3s ease;
}

@media screen and (max-width: 844px) {
  .menu-bg {
    background-attachment: scroll;
  }

  .menu-wrapper {
    background-color: white;
    margin: 10rem 0 10rem 0;
  }

  .menu-title {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 2000px) {
  .menu-title {
    padding-top: 12rem;
  }
}

@media screen and (min-width: 1200px) {
  .menu-title {
    padding-top: 8rem;
  }
}

