@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Open+Sans:wght@300;400;500;600;700&family=Lato:wght@100;300;400;700;900&display=swap");

@font-face {
  font-family: "Boots & Spurs";
  src: url("./constants/fonts/Boots&Spurs.ttf");
}

@font-face {
  font-family: "Pistol Grip Pump";
  src: url("./constants/fonts/PistolGripPump.ttf");
}

@font-face {
  font-family: "Lato-Regular";
  src: url("./constants/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("./constants/fonts/Lato/Lato-Bold.ttf");
}

:root {
  --font-base: "Pistol Grip Pump", serif;
  --font-alt: "Amatic SC", sans-serif;
  --font-p-reg: "Lato-Regular", sans-serif;
  --font-p-bold: "Lato-Bold", sans-serif;

  --color-orange: #ff8000;
  --color-blue: #0d72f5;
  --color-pink: #e60080;
  --color-green: #1b913e;
  --color-black: #0c0c0c;
  --color-gray: #545454;
  --color-crimson: #f5efdb;
  --color-grey: #aaaaaa;
  --color-white: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
