.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--color-black);
  opacity: 80%;
  padding: 0.5rem 2rem;
  z-index: 1;
}

.navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo-animation {
  transform-origin: left top;
  animation: scale 1800ms ease-in-out forwards;
}

@keyframes scale {
  0% {
    transform: scale(3.5);
  }
}

.navbar-logo:hover {
  animation: skew 2s infinite;
  animation-direction: alternate;
}

@keyframes skew {
  0% {
    transform: skew(5deg, 5deg);
  }
}

.navbar-logo img {
  width: 10rem;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.navbar-links li {
  margin: 0 1.5rem;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 900;
  text-decoration: none;
  transition: 0.5s ease;
}

.navbar-links li:hover {
  color: var(--color-blue);
  border-bottom: 2px solid var(--color-pink);
}

.navbar-smallscreen {
  display: none;
}

.navbar-smallscreen .overlay__open {
  cursor: pointer;
}

.navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: white;
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.navbar-smallscreen_links {
  list-style: none;
}

.navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: white;
  font-size: 3rem;
  text-align: center;
}

.navbar-smallscreen_links li:hover {
  color: var(--color-blue);
  transition: 0.3s ease;
}

@media screen and (min-width: 2000px) {
  .logo-animation {
    transform-origin: left top;
    animation: scale 1800ms ease-in-out forwards;
  }

  @keyframes scale {
    0% {
      transform: scale(10);
    }
  }
}

@media screen and (max-width: 2000px) {
  .navbar-logo img {
    width: 160px;
  }
}

@media screen and (max-width: 1150px) {
  .navbar-links {
    display: none;
  }

  .navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .navbar {
    padding: 1rem;
  }

  .navbar-logo img {
    width: 110px;
  }
}
